import React, { useState, useRef } from "react";
import { CardMedia, Box } from "@mui/material";

const images = [
  "book.png",
  "book.png",
  "book.png"
];

const SwipeableImageGallery = () => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const startX = useRef(0);
  const touchDiff = useRef(0);

  const handleTouchStart = (e) => {
    startX.current = e.touches[0].clientX;
  };

  const handleTouchMove = (e) => {
    touchDiff.current = e.touches[0].clientX - startX.current;
  };

  const handleTouchEnd = () => {
    if (touchDiff.current > 50) {
      setCurrentIndex((prevIndex) => (prevIndex > 0 ? prevIndex - 1 : images.length - 1));
    } else if (touchDiff.current < -50) {
      setCurrentIndex((prevIndex) => (prevIndex < images.length - 1 ? prevIndex + 1 : 0));
    }
    touchDiff.current = 0; // reset the diff
  };

  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        overflow: 'hidden',
        position: 'relative',
        width: '100%',
        height: 'auto'
      }}
    >
      <Box
        sx={{
          display: 'flex',
          transition: 'transform 0.5s ease-in-out',
          transform: `translateX(${-currentIndex * 100}%)`,
          width: `${images.length * 100}%`
        }}
        onTouchStart={handleTouchStart}
        onTouchMove={handleTouchMove}
        onTouchEnd={handleTouchEnd}
      >
        {images.map((image, index) => (
          <CardMedia
            key={index}
            component="img"
            image={image}
            alt={`Slide ${index}`}
            sx={{ width: '100%', flexShrink: 0 }}
          />
        ))}
      </Box>
    </Box>
  );
};

export default SwipeableImageGallery;
